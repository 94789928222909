/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { supabase } from "../../app/supabase_client";
import { useEffect, useState } from "react";

export default function Newsletter() {
  const [confirmation, setConfirmation] = useState(false);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function sendEmail(userData: any) {
    // Save email in interest_mail table
    try {
      const { data, error } = await supabase
        .from("interest_mail")
        .insert([{ email: userData.email, nom: userData.nom }]);
      if (error) throw error;
      setConfirmation(true);
      setError(false);
    } catch (error) {
      alert(error);
      setConfirmation(false);
      setError(true);
    }
  }

  useEffect(() => {
    // Reset confirmation message after 5 seconds
    const timer = setTimeout(() => {
      setConfirmation(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, [confirmation]);

  return (
    <>
      <Container
        className="text-center section-container d-flex align-items-center justify-content-center"
      >
        <Col>
          <Row>
            <h1 className="font-primary">
              Vous souhaitez suivre l'avancée du projet?
            </h1>
            <p className="mb-4 font-primary">
              Le projet vous intéresse et vous voulez suivre son avancée?{" "}
              <br></br>
              Alors n'hésitez pas à vous abonner à notre newsletter, et vous
              serez tenu au courant <br></br>
              jour par jour, semaine par semaine de son avancée.
            </p>
          </Row>
          <Row>
            <Form
              className="form-inline"
              onSubmit={handleSubmit((data) => sendEmail(data))}
            >
              <Row>
                <Col sm={5} className="mb-2">
                  <Form.Control
                    {...register("nom", { required: true })}
                    type="text"
                    placeholder="Votre Nom*"
                    className="form-control"
                  />
                </Col>
                <Col sm={6} className="mb-2">
                  <Form.Control
                    {...register("email", {
                      required: true,
                      pattern: /^\S+@\S+$/i,
                    })}
                    type="email"
                    placeholder="Votre mail*"
                    className="form-control"
                  />
                </Col>
                <Col sm={1} className="mb-2">
                  <Button type="submit" className="btn button-primary">
                    Envoyer
                  </Button>
                </Col>
              </Row>
              {confirmation && (
                <Alert variant="success">
                  Merci pour votre inscription! Vous recevrez bientôt notre
                  newsletter.
                </Alert>
              )}
              {error && (
                <Alert variant="danger">
                  Oups! Une erreur est survenue. Veuillez réessayer. Êtes-vous
                  sûr que vous n'êtes pas déjà inscrit?
                </Alert>
              )}
            </Form>
          </Row>
        </Col>
      </Container>
    </>
  );
}
