import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

function NavBar() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="light"
        className="navbar"
      >
        <Container
          className="custom-navbar-container navbar-container d-flex align-items-center justify-content-center"
          fluid
        >
          <Navbar.Brand href="#">
            <img
              src="BYGGA_FONDBLANC.png"
              height={125}
              className="d-inline-block align-top brand-logo"
              alt="Bygga Music Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className="align-middle pr-5">
              <Col className="custom-link">
                <Nav.Link href="#application">Application</Nav.Link>
              </Col>
              <Col className="custom-link">
                <Nav.Link href="#pourquoi">Pourquoi</Nav.Link>
              </Col>
              <Col className="custom-link">
                <Nav.Link className="" href="#newsletter">
                  Newsletter
                </Nav.Link>
              </Col>
              <Col className="custom-link">
                <Nav.Link href="#contacts">Contacts</Nav.Link>
              </Col>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
