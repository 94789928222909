import React from "react";
import "./App.css";
import Home from "./pages/home";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <Home></Home>
  );
}

export default App;
