import { Row, Col } from "react-bootstrap";
import Questionnaire from "./questionnaire";

export default function ApplicationSection() {
  return (
    <>
      <div
        id="application"
        className="text-center section-container d-flex align-items-center justify-content-center"
      >
        <Col>
          <Row>
            <Col md={4}>
              
            </Col>
            <Col md={8}>
              <Row>
                <h1
                  className=""
                  style={{
                    color: "white",
                  }}
                >
                  Bygga Music,
                  <br></br>
                  Unissez vos talents!
                </h1>
              </Row>
              <Row>
                <p
                  className="mb-4"
                  style={{
                    color: "white",
                  }}
                >
                  Tu as besoin d'un guitariste? D'un graphiste? D'un DJ?
                  <br></br>
                  Avec Bygga Music, retrouves facilement les personnes qui
                  t'aideront.<br></br>
                  Une solution sécurisée et fiable qui sera disponible sur tous
                  tes appareils!
                </p>
                <a
                href="https://drive.google.com/uc?export=download&id=1sXjQWrEvsApF66tng3ignQrAwJi28EJn"
                className="button-primary"
                style={{
                  color: "white",
                  backgroundColor: "#6805F2",
                  borderRadius: "10px",
                  padding: "10px",
                  textDecoration: "none",
                  transition: "0.3s",
                }}
              >Téléchargez La version de test pour android</a>
              <p
                  className="mb-4"
                  style={{
                    color: "white",
                  }}
                >
                  Uniquement sur Android pour le moment. Les données de paiements sont fictives.
                </p>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
}


