/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { supabase } from "../../app/supabase_client";
import { useEffect, useState } from "react";

export default function Contacts() {
  return (
    <>
      <Container
        id="contacts"
        className="text-center d-flex align-items-center justify-content-center pb-5"
      >
        <Col>
          <Row>
            <h1 className="font-primary">Vous souhaitez nous contacter?</h1>
            <p className="mb-4 font-primary">
              Le projet vous intéresse et vous voulez nous donner des
              suggestions ou des conseils?
              <br></br>
              Alors n'hésitez pas à nous contacter via le bouton ci-dessous.
            </p>
            <Col md={12} className="mb-2">
              <a
                href="mailto:byggamusic@gmail.com"
                type="button"
                className="btn button-primary"
              >
                Nous contacter
              </a>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
}
