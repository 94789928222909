import { Col, Container, Row } from "react-bootstrap";
export default function Pourquoi() {
  return (
    <>
      <Container
        id="pourquoi"
        className="section-container d-flex align-items-center justify-content-center"
      >
        <h1 className="font-primary mb-5">Pourquoi utiliser Bygga Music?</h1>
        <Row>
          <Col md={4} className="mb-5">
            <div className="image-container">
              <img
                src="autour.png"
                alt="Rencontrer d'autres acteurs de la musique à côté de chez toi"
              />
              <p
                style={{
                  fontSize: "1.3rem",
                }}
              >
                Rencontrer d'autres acteurs de la musique à côté de chez toi
              </p>
            </div>
          </Col>
          <Col md={4} className="mb-5">
            <div className="image-container">
              <img src="designer.png" alt="Trouver une identité graphique" />
              <p
                style={{
                  fontSize: "1.3rem",
                }}
              >
                Trouve ton identité graphique grâce à des designers autours de
                toi
              </p>
            </div>
          </Col>
          <Col md={4} className="mb-5">
            <div className="image-container">
              <img src="partition.png" alt="Vendre tes partitions" />
              <p
                style={{
                  fontSize: "1.3rem",
                }}
              >
                Partager ou vendre tes partitions avec d'autres artistes et musiciens
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
