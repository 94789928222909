import { Col, Container, Row } from "react-bootstrap";
import { AiFillInstagram } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { BsLinkedin } from "react-icons/bs";
export default function Footer() {
  // Return responsive footer with 3 columns each equal in size
  return (
    <footer className="d-flex align-items-center justify-content-center footer p-5">
      <Container>
        <Row>
          <Col
            md={4}
            className="text-center d-flex align-items-center justify-content-center"
          >
            <a href="https://www.instagram.com/bygga_music/">
              <AiFillInstagram className="mx-2" size={40} color="white" />
            </a>
            <a href="https://www.tiktok.com/@byggamusic?_t=8aKjUGssn6U&_r=1">
              <FaTiktok className="mx-2" size={40} color="white" />
            </a>
            <a href="https://www.linkedin.com/company/bygga-music/">
              <BsLinkedin className="mx-2" size={40} color="white" />
            </a>
          </Col>
          <Col
            md={4}
            className="text-center d-flex align-items-center justify-content-center"
          >
            <img src="BYGGA_FONDNOIR.png" alt="Logo" height={80} />
          </Col>
          <Col
            md={4}
            className="text-center d-flex align-items-center justify-content-center"
          >
            <h3>&copy;{new Date().getFullYear()} Bygga</h3>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
