import { Row, Col } from "react-bootstrap";

export default function ApplicationSection2() {
  return (
    <>
      <div
        id="application2"
        className="text-center section-container d-flex align-items-center justify-content-center"
      >
        <Col>
          <Row>
            <Col>
              <h1 className="font-primary">
                A quoi peut vous servir <br></br>
                <img height={80} src="BYGGA_FONDBLANC.png" alt="whybygga"></img>?
              </h1>
            </Col>
          </Row>
          <Row>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <img src="phone1.png" alt="phone1" height={"700px"} />
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <img src="phone2.png" alt="phone2" height={"700px"} />
            </Col>
          </Row>
        </Col>
      </div>
    </>
  );
}
