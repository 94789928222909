/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { supabase } from "../../app/supabase_client";
import { useEffect, useState } from "react";

export default function Questionnaire() {
  return (
    <>
      <Container
        id="questionnaire"
        className="text-center d-flex align-items-center justify-content-center pb-5"
      >
        <Col>
          <Row>
            <h1 className="font-primary">Vos avis avant tout</h1>
            <p className="mb-4 font-primary">
              Vos avis et attentes nous intéressent très fortement.
              <br></br>
              N'hésitez pas à répondre à notre questionnaire ci-dessous!
            </p>
            <Col md={12} className="mb-2">
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSd40k4h4y69--sFxervPk7ZIr6hdVy2Ja89y6IZ6jx_MG8bWw/viewform"
                type="button"
                className="btn button-primary"
                target="_blank"
              >
                Répondre au questionnaire
              </a>
            </Col>
          </Row>
        </Col>
      </Container>
    </>
  );
}
