import NavBar from "../../components/common/navbar";
import Footer from "../../components/common/footer";
import Newsletter from "./newsletter";
import ApplicationSection from "./application";
import Pourquoi from "./pourquoi";
import ApplicationSection2 from "./application2";
import Contacts from "./contacts";
import { Row, Col } from "react-bootstrap";
import Questionnaire from "./questionnaire";

export default function Home() {
  return (
    <>
      <NavBar></NavBar>
      {/* <CustomNavbar></CustomNavbar> */}
      <div className="main-container">
        <ApplicationSection></ApplicationSection>
        <ApplicationSection2></ApplicationSection2>
        <Pourquoi></Pourquoi>
        <div className="section-container blur-bg">
          <Row className="news">
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
              id="viber"
            >
              <img src="viber.png" alt="bygga-viber"></img>
            </Col>
            <Col md={8} id="newsletter" className="background-priority">
              <Questionnaire></Questionnaire>
              <Newsletter></Newsletter>
              <Contacts></Contacts>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
              id="singer"
            >
              <img src="singer.png" alt="bygga-singer"></img>
            </Col>
          </Row>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
